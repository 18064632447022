<template>
  <div class="seeMore">
    <div class="seeMore-center">
      <Box>
        <div class="flex">
          <div @click="huitui" class="back-button" style="float: right"></div>
          <div class="left">
            <div class="left-input">
              <div v-for="item in leftList" :key="item.name" class="marBottom">
                <div class="left-img marBottom" v-if="item.type === 3">
                  <img src="@/assets/platform/traffic-control.jpg" alt="" />
                </div>
                <div v-if="item.type === 1">
                  <span style="color: #9fc4ff">{{ item.name }}</span>
                  <el-input :value="item.value" readonly />
                </div>
                <div v-if="item.type === 2">
                  <span style="color: #9fc4ff">{{ item.name }}</span>
                  <el-input
                    type="textarea"
                    :rows="8"
                    readonly
                    v-model="item.value"
                    class="miao"
                  />
                </div>
                <!-- <div v-if="item.type === 4 && user_info_hn.departmentId===60">
                  <span style="color: #9FC4FF;">{{ item.name }}</span>
                  <el-input :value="item.value" readonly />
            </div> -->
              </div>
            </div>
          </div>
          <div class="center">
            <div v-for="item in centerList" :key="item.name" class="marBottom">
              <div v-if="!item.type">
                <span style="color: #9fc4ff">{{ item.name }}</span>
                <el-input :value="item.value" readonly />
              </div>
              <div v-if="item.type === 2 && user_info_hn.uid != uid">
                <span style="color: #9fc4ff">{{ item.name }}</span>
                <el-input :value="item.value" readonly />
              </div>
              <!-- <div v-if="item.type === 4 && user_info_hn.departmentId===60">
                  <span style="color: #9FC4FF;">{{ item.name }}</span>
                  <el-input :value="item.value" readonly />
            </div> -->
            </div>
          </div>
          <div class="right">
            <div
              v-for="item in rightList"
              :key="item.name"
              :class="
                item.type === 3 && user_info_hn.uid != uid ? null : 'marBottom'
              "
            >
              <div v-if="!item.type">
                <span style="color: #9fc4ff">{{ item.name }}</span>
                <el-input :value="item.value" readonly />
              </div>
              <div v-else-if="item.type === 3 && user_info_hn.uid === uid">
                <span style="color: #9fc4ff">{{ item.name }}</span>
                <el-input :value="item.value" readonly />
              </div>
              <div v-else-if="item.type === 2 && user_info_hn.uid != uid">
                <span style="color: #9fc4ff">{{ item.name }}</span>
                <el-input :value="item.value" readonly />
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="grid">
        <div v-for="item in leftList" :key="item.name" class="marBottom">
            <div class="left-img marBottom; grid-row-start: 1; grid-row-end: 3; grid-column-start: 1; grid-column-end: 3;" v-if="item.type === 3"></div>
            <span style="color: #9FC4FF;">{{ item.name }}</span>
            <el-input v-if="item.type === 1" :value="item.value" readonly />
            <el-input
              v-if="item.type === 2"
              type="textarea"
              :rows="5"
              readonly
              v-model="item.value"
              class="miao"
            />
        </div>
        <div v-for="item in centerList" :key="item.name" class="marBottom">
          <span style="color: #9FC4FF;">{{ item.name }}</span>
          <el-input :value="item.value" readonly />
        </div>
        <div v-for="item in rightList" :key="item.name" class="marBottom">
          <span style="color: #9FC4FF;">{{ item.name }}</span>
          <el-input :value="item.value" readonly />
        </div>
      </div> -->
      </Box>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import Box from "@/components/platform/common/box";
import API from "@/api";
export default {
  components: {
    Box,
  },
  data() {
    return {
      leftList: [
        { name: "", value: "", type: 3 },
        { name: "配置人员", value: "", type: 1 },
        { name: "配置车辆", value: "", type: 1 },
        { name: "选配AI", value: "", type: 1 },
        { name: "派单单位", value: "", type: 1 },
        { name: "需求描述", value: "", type: 2 },
      ],
      centerList: [
        { name: "任务名称", value: "" },
        { name: "场景类型", value: "" },
        { name: "任务状态", value: "" },
        { name: "配备无人机", value: "" },
        { name: "实际作业天数", value: "" },
        { name: "运维人员", value: "" },
        { name: "联系人", value: "" },
        { name: "作业位置", value: "" },
        // { name: "系统通知", value: "", type: 1 },
        { name: "对接人", value: "" },
        { name: "联系电话", value: "", type: 2 },
      ],
      rightList: [
        { name: "任务类型", value: "" },
        { name: "审批状态", value: "" },
        { name: "处理状态", value: "" },
        { name: "配置挂载", value: "" },
        { name: "航线名称", value: "" },
        { name: "派单人", value: "" },
        { name: "联系电话", value: "" },
        { name: "飞行时间", value: "" },
        { name: "提交时间", value: "", type: 3 },
        { name: "需求单位", value: "", type: 2 },
        { name: "处理需求时间", value: "", type: 2 },
      ],
      mapList: {
        status: {
          [-1]: "已取消",
          0: "待执行",
          1: "已完成",
          3: "执行中",
        },
        apply: {
          [-1]: "暂无",
          0: "待审批",
          1: "审批通过",
          2: "审批不通过",
          3: "默认通过",
        },
        processing: {
          0: "待处理",
          1: "处理完成",
          2: "无需处理",
        },
      },
      uid: null,
    };
  },
  methods: {
    async async_get() {
      const id = this.$route.params.id;
      const res = await API.TASK.Get(id);
      this.uid = res.data.userId;
      //左侧列表
      this.leftList[1].value = res.data.providePersonCount;
      this.leftList[2].value = res.data.provideCarCount;
      this.leftList[3].value = res.data.ai;
      this.leftList[4].value = res.data.handleUserName;
      this.leftList[5].value = res.data.remark;

      //中间列表
      this.centerList[0].value = res.data.taskTitle;
      this.centerList[1].value = res.data.taskTypeName;
      this.centerList[2].value = this.mapList.status[res.data.taskStatus];
      this.centerList[3].value = res.data.deviceType.map(item => item.name).join(',')
      this.centerList[4].value = res.data.workDayCount;
      this.centerList[5].value = res.data.operators[0];
      this.centerList[6].value = res.data.handleUserName;
      this.centerList[7].value = `${res.data.destination}-${res.data.origin}`;
      this.centerList[8].value = res.data.userName;
      this.centerList[9].value = res.data.phone;

      //右侧列表
      this.rightList[0].value = res.data.taskCateName;
      (this.rightList[1].value = this.mapList.apply[res.data.papplyStatus]),
        (this.rightList[2].value =
          this.mapList.processing[res.data.processingStatus]),
        (this.rightList[3].value = res.data.mountType
          .map((item) => item.name)
          .join(","));
      this.rightList[4].value = res.data.flightName;
      this.rightList[5].value = res.data.handleUserName;
      this.rightList[6].value = res.data.phones;
      this.rightList[7].value = `${res.data.startedAt}-${res.data.endAt}`;
      this.rightList[8].value = res.data.addTime;
      this.rightList[9].value = res.data.organizationName;
      this.rightList[10].value = res.data.handleTime;
    },
    huitui() {
      this.$router.go(-1);
    },
    // type(value) {
    //   switch (value) {
    //     case 1:
    //       return 'item1 marBottom'
    //       break;
    //     case 2:
    //       return 'item2 marBottom'
    //       break;
    //     case 3:
    //       return 'item3 marBottom'
    //       break;
    //     default:
    //       break;
    //   }
    // }
  },
  computed: {
    ...mapGetters(["user_info_hn"]),
  },
  mounted() {
    this.async_get();
  },
};
</script>

<style lang="scss" scoped>
.seeMore {
  width: 100%;
  height: 930px;
  display: flex;
  justify-content: center;
  &-center {
    width: 1288px;
    padding-top: 35px;
  }
  .flex {
    display: flex;
    margin: 0 73px;
  }
  .left {
    display: grid;
    width: 350px;
    height: 100%;
    margin-top: 26px;
    &-img {
      height: 192px;
      border: 1px solid rosybrown;
      padding-bottom: 0;
    }
    &-input {
      height: 250px;
    }
    &-text {
      height: 130px;
    }
  }
  .center {
    width: 350px;
    height: 100%;
    margin-left: 24px;
    margin-top: 26px;
  }
  .right {
    width: 350px;
    height: 100%;
    margin-left: 24px;
    margin-top: 26px;
  }
  .marBottom {
    padding-bottom: 12px;
    .el-input {
      padding-top: 4px;
    }
    .miao {
      padding-top: 4px;
    }
  }
  .marBottom-hb {
    padding-bottom: 0;
    .el-input {
      padding-top: 4px;
    }
    .miao {
      padding-top: 4px;
    }
  }
}
</style>
<style>
.miao .el-textarea__inner {
  height: 100px;
  background: rgba(12, 34, 67, 0.5);
  border: 1px solid #009aff;
  color: #9fc4ff;
  font-size: 16px;
  min-height: 171px !important;
}
.service-box-inner {
  justify-content: center;
}

.marBottom .el-input .el-input__inner {
  height: 36px;
  box-sizing: border-box;
  background: rgba(12, 34, 67, 0.5);
  border: 1px solid #009aff;
  border-radius: 0;
  /* font-size: 12px; */
  color: #9fc4ff;
  letter-spacing: 0;
  font-weight: 400;
  line-height: 36px;
}
</style>
<style>
.item1 {
  grid-area: input;
}
.item2 {
  grid-area: text;
}
.item3 {
  grid-area: img;
}
.grid {
  display: grid;
  grid-template-columns: 350px 350px 350px;
  /* grid-template-areas:
    'img input input'
    'img input input'
    'input input input'
    'input input input'
    'input input input'
    'input input input'
    'input input input'
    'text input input'
    'text input input'
    'input input input' */
}

.back-button {
  width: 20px;
  height: 20px;
  right: 32px;
  position: absolute;
  cursor: pointer;
  background-image: url(~@/assets/platform/message/task/details/closure.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
